/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
:host ::ng-deep .mat-form-field-underline {
  display: none;
}
.mat-progress-bar-fill::after {
  background-color: #ff1538;
}

/* Default styling for the drop area */
.border-dashed {
  transition: all 0.3s ease-in-out;
}

/* Add styles for when a file is being dragged over */
.drag-over {
  background-color: #e8f5e9; /* Light green background */
  border-color: #4caf50;    /* Green border to indicate active state */
  color: #4caf50;           /* Optional: Change text/icons color */
}
